/*eslint-disable*/
import React from "react";
import Link from 'next/link'
import { useRouter } from 'next/router'

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import Popover from "@material-ui/core/Popover";

// @material-ui/icons
import { Apps, CloudDownload } from "@material-ui/icons";

// core components
import CustomDropdown from "/components/CustomDropdown/CustomDropdown.js";
import Button from "/components/CustomButtons/Button.js";
import { LocationOn } from "@material-ui/icons";

import { localeText } from "constants.js";

import styles from "/styles/jss/bbhost/pages/componentsSections/navbarsStyle.js";

const useStyles = makeStyles(styles);

export default function LocationNav() {
    const classes = useStyles();
    const router = useRouter()
    const { locale, locales, defaultLocale, pathname } = router

    const actualLocale = (
        <>
            {localeText[locale].icon}  {localeText[locale].text} 
        </>
    );

    const localeList = locales.map((local_slug, key) => {        
        if(local_slug !== locale)
        {
            if(defaultLocale === local_slug)
                return (            
                    <Button
                        href={pathname}
                        color="transparent"
                        className={classes.dropdownLink}                    
                    >
                        {localeText[local_slug].icon} {localeText[local_slug].text}
                    </Button>
                );
            else
                return (            
                    <Button
                        href={"/" + local_slug + pathname}
                        color="transparent"
                        className={classes.dropdownLink}             
                    >
                        {localeText[local_slug].icon} {localeText[local_slug].text}
                    </Button>
                );
        }
        return (<></>);
    });

    return (
        <ListItem className={classes.listItem}>
            <CustomDropdown
                noLiPadding
                navDropdown
                hoverColor="info"
                buttonText={actualLocale}
                buttonProps={{
                    className: classes.navLink,
                    color: "transparent",
                }}
                dropdownList={localeList}
            />
        </ListItem>
    );
}
